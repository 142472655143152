<template>
    <div>
        <div class="navBoxFlex">
            <el-row>
                <transition v-if="$companyType == 0" name="fade-transform">
                    <ComTable
                        v-if="tableShow === 1"
                        id="table1"
                        :condition="condition"
                        @enter-course="
                            () => {
                                $emit('enter-course');
                            }
                        "
                        @change-table="changeTable"
                    />
                </transition>
                <transition name="fade-transform">
                    <CourseTable
                        v-if="tableShow === 2"
                        id="table2"
                        :condition="condition"
                        @change-table="changeTable"
                    />
                </transition>
                <transition name="fade-transform">
                    <SingleCom
                        v-if="tableShow === 3"
                        id="table3"
                        :condition="condition"
                        @change-table="changeTable"
                    />
                </transition>
            </el-row>
        </div>
    </div>
</template>

<script>
import SingleCom from "./SingleCom";
import CourseTable from "./CourseTable";
import ComTable from "./ComTable";

export default {
    name: "Index",
    components: {
        SingleCom,
        CourseTable,
        ComTable,
    },
    props: {
        condition: "",
    },
    data() {
        return {
            tableShow: 1,
        };
    },
    watch: {
        condition: {
            handler(v) {},
            deep: true,
        },
    },
    created() {
        this.tableShow = this.$companyType == 0 ? 1 : 2;
    },
    methods: {
        changeTable({ number, row }) {
            this.tableShow = number;
            this.condition.row = row;
            this.$emit("table-show", number);
            // alert(this.condition.startTime)
        },
    },
};
</script>

<style scoped>
.table-head {
    width: 100%;
    color: rgb(92, 139, 255);
    border: 1px solid #e8eaec;
    font-size: 18px;
}

.table-div {
    border: 1px solid #e8eaec;
    height: 50px;
    box-sizing: border-box;
}

.table-div-title {
    font-weight: 700;
}

.company_name {
    width: 100%;
    height: 30px;
    /*border: 1px solid #5A8CFF;*/
}

.table-font {
    font-size: 35px;
}
/*#table1,#table2,#table3{*/
/*  display: none;*/
/*}*/
</style>
