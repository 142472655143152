<template>
	<el-row style="position: relative;height: 705px;">
		<vxe-toolbar>
			<template v-slot:buttons>
				<div class="flex justify-between">
					<vxe-button v-show="changeTable" @click="returnTable">返回</vxe-button>
					<div />
					<div>
						<vxe-button @click="getList">刷新</vxe-button>
						<vxe-button v-if="$hasBtn('sys:permissions:report:safety:import')" @click="exportTableE">导出excel</vxe-button>
						<!--            <vxe-button @click="exportTableP">导出PDF</vxe-button>-->
					</div>
				</div>
			</template>
		</vxe-toolbar>
		<div id="table1">
			<div v-show="condition.statisticalDimension==0">
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="600" :data="table.list" :loading="loading" @cell-click="cellClick">
					<vxe-table-column field="courseName" title="课程名称" show-overflow="title" />
					<vxe-table-column title="有效期" show-overflow="title">
						<template v-slot="{row}">
							<span>{{ row.startTime+'-'+row.endTime }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column field="areaNum" title="区域总数" show-overflow="title" />
					<vxe-table-column field="completeAreaNum" title="完成区域数" show-overflow="title" />
					<vxe-table-column field="undoneAreaNum" title="未完成区域数" show-overflow="title" />
					<vxe-table-column field="areaCompletionRate" title="完成率(%)" show-overflow="title">
						<template v-slot="{row}">
							<span>{{ row.areaCompletionRate }}</span>
						</template>
					</vxe-table-column>
				</vxe-table>

			</div>
			<div v-show="condition.statisticalDimension==1">
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="550" :data="table.list" :loading="loading" @cell-click="cellClick">
					<vxe-table-column field="courseName" title="课程名称" show-overflow="title" />
					<vxe-table-column title="有效期" show-overflow="title">
						<template v-slot="{row}">
							<span>{{ row.startTime+'-'+row.endTime }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column field="companyNum" title="企业总数" show-overflow="title" />
					<vxe-table-column field="completeCompanyNum" title="完成企业数" show-overflow="title" />
					<vxe-table-column field="undoneCompanyNum" title="未完成企业数" show-overflow="title" />
					<vxe-table-column field="companyCompletionRate" title="企业完成率(%)" show-overflow="title" />
					<vxe-table-column field="traineeNum" title="学员总数" show-overflow="title" />
					<vxe-table-column field="completeTraineeNum" title="完成学员数" show-overflow="title" />
					<vxe-table-column field="undoneTraineeNum" title="未完成学员数" show-overflow="title" />
					<vxe-table-column field="traineeCompletionRate" title="学员完成率(%)" show-overflow="title">
						<template v-slot="{row}">
							<span>{{ row.traineeCompletionRate }}</span>
						</template>
					</vxe-table-column>
				</vxe-table>
			</div>
		</div>
		<div id="table2">
			<div>
				<div class="flex">
					<div class="cell-outer cell-outer-title">课程名称</div>
					<div class="cell-outer cell-outer-title flex-1">
						{{ course.courseName }}
					</div>
				</div>
				<div class="flex">
					<div class="cell-outer cell-outer-title">课程详情</div>
					<div class="cell-outer cell-outer-title flex-1">
						{{ course.courseIntroduce }}
					</div>
				</div>
				<div class="flex">
					<div class="cell-outer cell-outer-title">课程有效期</div>
					<div class="cell-outer cell-outer-title flex-1">
						{{ course.startTime+'-'+course.endTime }}
					</div>
				</div>
			</div>
			<div v-show="condition.statisticalDimension==0">
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" :height="changeHeight" :data="table.list" :loading="loading">

					<vxe-table-column field="areaName" title="行政区域" show-overflow="title" />
					<vxe-table-column field="companyNum" title="企业总数" show-overflow="title" />
					<vxe-table-column field="completeCompanyNum" title="完成企业数" show-overflow="title" />
					<vxe-table-column field="undoneCompanyNum" title="未完成企业数" show-overflow="title" />
					<vxe-table-column field="completionRate" title="完成率(%)" show-overflow="title">
						<template v-slot="{row}">
							<span>{{ row.completionRate }}</span>
						</template>
					</vxe-table-column>
				</vxe-table>
			</div>
			<div v-show="condition.statisticalDimension==1">
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" :height="changeHeight" :data="table.list" :loading="loading">
					<vxe-table-column field="companyName" title="企业名称" show-overflow="title" />
					<vxe-table-column field="areaName" title="所属区域" show-overflow="title" />
					<vxe-table-column field="traineeNum" title="学员总数" show-overflow="title" />
					<vxe-table-column field="completeTraineeNum" title="完成学员数" show-overflow="title" />
					<vxe-table-column field="undoneTraineeNum" title="未完成学员数" show-overflow="title" />
					<vxe-table-column field="completionRate" title="完成率(%)" show-overflow="title">
						<template v-slot="{row}">
							<span>{{ row.completionRate }}</span>
						</template>
					</vxe-table-column>
				</vxe-table>
			</div>
		</div>
		<div style="height: 20px;margin-top: 600px" />
		<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
		 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
		 @current-change="handleCurrentChange" />
	</el-row>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		staCourse,
		staCourseDetails,
		staCourseE,
		staCourseDetailsE
	} from '@/api'
	import {
		bytesToFile
	} from '@/utils/handleByte'
	export default {
		name: 'Index',
		components: {},
		props: {
			condition: ''
		},
		data() {
			return {
				loading: false,
				columnShow: false,
				changeTable: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				changeHeight: 550,
				searchUrl: staCourse,
				staCourse,
				staCourseDetails,
				exportUrl: staCourseE,
				course: {}
			}
		},
		watch: {
			condition: {
				handler(v) {
					this.table.currentPage = 1
					this.returnTable()
				},
				deep: true
			}
		},
		created() {
			// this.getList()
		},
		mounted() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			cellClick({
				row
			}) {
				this.changeTable = true
				const e1 = document.querySelector('#table1')
				const e2 = document.querySelector('#table2')
				if (this.changeTable) {
					e1.style.left = '-2000px'
					e2.style.left = 0
					this.changeHeight = 480
				}
				this.searchUrl = staCourseDetails
				this.exportUrl = staCourseDetailsE
				this.condition.id = row.id
				this.getList()
			},
			returnTable() {
				this.changeTable = false
				const e1 = document.querySelector('#table1')
				const e2 = document.querySelector('#table2')
				if (!this.changeTable) {
					e2.style.left = '-2000px'
					e1.style.left = 0
				}
				this.searchUrl = staCourse
				this.exportUrl = staCourseE
				this.getList()
			},
			exportTableE() {
				this.loading = true
				this.$axiosReq(this.exportUrl, null, {
					statisticalGranularity: this.condition.statisticalGranularity,
					statisticalDimension: this.condition.statisticalDimension,
					startTime: this.condition.startTime,
					endTime: this.condition.endTime,
					areaCode: this.condition.areaCode,
					id: this.condition.id,
					courseName: this.condition.courseName
				}, 'get').then(res => {
					bytesToFile(res.data.bytes, res.data.fileName)
				}).finally(e => {
					this.loading = false
				})
			},
			getList() {
				this.loading = true
				this.$axiosReq(this.searchUrl, null, {
					statisticalGranularity: this.condition.statisticalGranularity,
					statisticalDimension: this.condition.statisticalDimension,
					startTime: this.condition.startTime,
					endTime: this.condition.endTime,
					areaCode: this.condition.areaCode,
					id: this.condition.id,
					courseName: this.condition.courseName,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					if (this.searchUrl === this.staCourse) {
						this.table.list = res.data.content
						this.table.total = Number(res.data.totalElements)
					} else {
						this.course = res.data
						this.table.list = res.data.pageVo.content
						this.table.total = Number(res.data.pageVo.totalElements)
					}
				}).finally(e => {
					this.loading = false
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			}
		}
	}
</script>

<style scoped>
	#table1 {
		position: absolute;
		width: 100%;
		/* transition: 0.8s linear; */
	}

	#table2 {
		position: absolute;
		width: 100%;
		left: -2000px;
		/* transition: 0.8s linear; */
	}

	.cell-outer {
		border: 1px solid #e8eaec;
	}

	.cell-outer-title {
		width: 325px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.cell-outer-title::-webkit-scrollbar {
		display: none;
	}

	.vxe-table-element {
		cursor: pointer;
	}
</style>
