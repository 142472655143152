<template>
	<div class="flex">
		<div>
			<span class="text-primary text-pad-right">学员姓名</span>
			<el-input v-model="condition.realName" class="input searchInput" size="mini" style="width: 200px" suffix-icon="el-icon-search"
			 placeholder="搜索" @input="changeName" />
		</div>
		<div class="ml-2">
			<span class="text-primary text-pad-right">手机号码</span>
			<el-input v-model="condition.phone" class="input searchInput" style="width: 200px" size="mini" suffix-icon="el-icon-search"
			 placeholder="搜索" @input="changePhone" />
		</div>
		<div class="ml-2">
			<span class="text-primary text-pad-right">公司</span>
			<el-select v-model="condition.companyId" size="mini" filterable style="width: 200px" class="input searchInput"
			 @change="changeCompany">
				<el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
			</el-select>
		</div>
	</div>
</template>

<script>
	import {
		company
	} from '@/api'

	export default {
		name: 'UserSearch',
		data() {
			return {
				loading: false,
				condition: {
					company: {},
					date: '',
					search: '',
					train: 1,
					companyId: '',
					phone: '',
					realName: ''
				},
				company: [],
			}
		},
		created() {
			this.getCompany()
		},
		methods: {
			getCompany() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content;
					this.condition.companyId = res.data.content[0].id;
				})
			},
			changeCompany(v) {
				this.$emit('get-company', v)
			},
			changeName(v) {
				this.$emit('get-name', v)
			},
			changePhone(v) {
				this.$emit('get-phone', v)
			}
		}
	}
</script>

<style scoped>

</style>
