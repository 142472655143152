<template>
    <div>
        <el-row>
            <template>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <div class="flex justify-between">
                            <vxe-button v-if="$companyType == 0" @click="change"
                                >返回</vxe-button
                            >
                            <div />
                            <div>
                                <vxe-button @click="getList">刷新</vxe-button>
                                <vxe-button @click="exportTableE"
                                    >导出excel</vxe-button
                                >
                                <!--                <vxe-button @click="exportTableP">导出PDF</vxe-button>-->
                            </div>
                        </div>
                    </template>
                </vxe-toolbar>
                <!-- <div class="flex justify-center pb-1">
          {{ company.companyName }}
        </div> -->
                <vxe-table
                    id="user_id"
                    ref="table"
                    border
                    export-config
                    resizable
                    height="620"
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    :column-key="true"
                    class="vxe-table-element"
                    :data="table.list"
                    :print-config="{}"
                    :loading="loading"
                    @cell-click="cellClick"
                >
                    <vxe-table-column field="courseName" title="课程名称" />
                    <vxe-table-column title="有效期">
                        <template v-slot="{ row }">
                            <span>{{ row.startTime + "-" + row.endTime }}</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="traineeNum" title="学员总数" />
                    <vxe-table-column
                        field="completeTraineeNum"
                        title="完成学员数"
                    />
                    <vxe-table-column
                        field="undoneTraineeNum"
                        title="未完成学员数"
                    />
                    <vxe-table-column title="学员完成率(%)">
                        <template v-slot="{ row }">
                            <span>{{ row.completionRate }}</span>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </template>
            <el-pagination
                background
                :current-page="table.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size="table.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="table.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                style="position: relative; top: 10px"
            />
        </el-row>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { staCompanyDetail, staCompanyDetailE, staFirm, staFirmE } from "@/api";
import { bytesToFile } from "@/utils/handleByte";

export default {
    name: "Index",
    components: {},
    props: {
        condition: "",
    },
    data() {
        return {
            tableHeight: 600,
            loading: false,
            table: {
                list: [],
                total: 0,
                pageTotal: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            company: {
                companyName: "",
                id: "",
            },
        };
    },
    watch: {
        condition: {
            handler() {
                this.getList();
            },
            deep: true,
        },
    },
    created() {
        this.getList();
    },
    mounted() {
        // this.getList()
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        cellClick({ row }) {
            row.companyId = this.company.id;
            this.$emit("change-table", { number: 3, row: row });
        },
        change() {
            this.$emit("change-table", { number: 1 });
        },
        exportTableE() {
            this.loading = true;
            if (this.$companyType == 0) {
                this.$axiosReq(
                    staCompanyDetailE,
                    null,
                    {
                        courseName: this.condition.name,
                        startTime: this.condition.startTime,
                        endTime: this.condition.endTime,
                        id: this.condition.row.id,
                    },
                    "get"
                )
                    .then((res) => {
                        bytesToFile(res.data.bytes, res.data.fileName);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            } else {
                this.$axiosReq(
                    staFirmE,
                    null,
                    {
                        courseName: this.condition.name,
                        startTime: this.condition.startTime,
                        endTime: this.condition.endTime,
                    },
                    "get"
                )
                    .then((res) => {
                        bytesToFile(res.data.bytes, res.data.fileName);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            }
        },
        getList() {
            this.loading = true;
            // console.log(this.condition)
            if (this.$companyType == 0) {
                this.$axiosReq(
                    staCompanyDetail,
                    null,
                    {
                        courseName: this.condition.name,
                        startTime: this.condition.startTime,
                        endTime: this.condition.endTime,
                        id: this.condition.row.id,
                        currentPage: this.table.currentPage,
                        pageSize: this.table.pageSize,
                    },
                    "get"
                )
                    .then((res) => {
                        this.table.list = res.data.pageVo.content;
                        this.table.total = Number(
                            res.data.pageVo.totalElements
                        );
                        this.company.companyName = res.data.companyName;
                        this.company.id = res.data.id;
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            } else {
                this.$axiosReq(
                    staFirm,
                    null,
                    {
                        courseName: this.condition.name,
                        startTime: this.condition.startTime,
                        endTime: this.condition.endTime,
                        currentPage: this.table.currentPage,
                        pageSize: this.table.pageSize,
                    },
                    "get"
                )
                    .then((res) => {
                        this.table.list = res.data.content;
                        this.table.total = Number(res.data.totalElements);
                        this.company.companyName = sessionStorage.UserName;
                        this.company.id = sessionStorage.CompanyId;
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            }
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
    },
};
</script>

<style scoped>
.table-head {
    width: 100%;
    color: rgb(92, 139, 255);
    border: 1px solid #e8eaec;
    font-size: 18px;
}

.table-div {
    border: 1px solid #e8eaec;
    height: 50px;
    box-sizing: border-box;
}

.table-div-title {
    font-weight: 700;
}

.company_name {
    width: 100%;
    height: 30px;
    /*border: 1px solid #5A8CFF;*/
}

.table-font {
    font-size: 35px;
}

.vxe-table-element {
    cursor: pointer;
}
</style>
