<template>
    <div>
        <transition name="fade-transform">
            <User
                v-if="tableShow"
                id="table1"
                :condition="condition"
                @change-table="changeTable"
            />
        </transition>
        <transition name="fade-transform">
            <ViewUser
                v-if="!tableShow"
                id="table2"
                :condition="condition"
                @change-table="changeTable"
            />
        </transition>
    </div>
</template>

<script>
import ViewUser from "./ViewUser";
import User from "./User";
export default {
    name: "Index",
    components: {
        ViewUser,
        User,
    },
    props: {
        condition: "",
    },
    data() {
        return {
            tableShow: true,
        };
    },
    watch: {},
    created() {},
    methods: {
        changeTable({ isShow, row }) {
            this.tableShow = isShow;
            this.condition.row = row;
        },
    },
};
</script>

<style scoped></style>
