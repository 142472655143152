<template>
    <div>
        <el-row>
            <template>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <div class="flex justify-between">
                            <div />
                            <div>
                                <vxe-button @click="getList">刷新</vxe-button>
                                <vxe-button @click="exportTableE"
                                    >导出excel</vxe-button
                                >
                                <!--                <vxe-button @click="exportTableP">导出PDF</vxe-button>-->
                            </div>
                        </div>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    id="user_id"
                    ref="table"
                    border
                    export-config
                    resizable
                    height="620"
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    :column-key="true"
                    class="vxe-table-element"
                    :data="table.list"
                    :print-config="{}"
                    :loading="loading"
                    @cell-click="cellClick"
                >
                    <vxe-table-column field="companyName" title="企业名称" />
                    <!-- <vxe-table-column field="region" title="所属区域" /> -->
                    <vxe-table-column title="所属区域">
                        <template v-slot="{ row }">
                            <span v-if="row.provinceName"
                                >{{ row.provinceName }} -
                            </span>
                            <span v-if="row.cityName"
                                >{{ row.cityName }} -
                            </span>
                            <span v-if="row.areaName">{{ row.areaName }} </span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="traineeNum" title="学员总数" />
                    <vxe-table-column field="courseNum" title="课程总数" />
                    <vxe-table-column
                        field="completeCourseNum"
                        title="完成课程数"
                    />
                    <vxe-table-column
                        field="undoneCourseNum"
                        title="未完成课程数"
                    />
                    <vxe-table-column
                        field="processingCourseNum"
                        title="进行中课程数"
                    />
                    <vxe-table-column title="完成率(%)">
                        <template v-slot="{ row }">
                            <span>{{ row.completionRate }}</span>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </template>
            <el-pagination
                background
                :current-page="table.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size="table.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="table.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                style="position: relative; top: 10px"
            />
        </el-row>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { staCompany, staCompanyE } from "@/api";
import { bytesToFile } from "@/utils/handleByte";

export default {
    name: "Index",
    components: {},
    props: {
        condition: "",
    },
    data() {
        return {
            tableHeight: 600,
            loading: false,
            columnShow: false,
            exportDisplay: "show",
            table: {
                list: [],
                total: 0,
                pageTotal: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
        };
    },
    watch: {
        condition: {
            handler(v) {
                this.table.list = [];
                this.table.currentPage = 1;
                this.getList();
            },
            deep: true,
        },
    },
    created() {
        this.getList();
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        cellClick({ row }) {
            this.$emit("enter-course");
            this.$emit("change-table", { number: 2, row: row });
        },
        exportTableE() {
            this.loading = true;
            this.$axiosReq(
                staCompanyE,
                null,
                {
                    companyName: this.condition.name,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                staCompany,
                null,
                {
                    companyName: this.condition.name,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
    },
};
</script>

<style scoped>
.table-head {
    width: 100%;
    color: rgb(92, 139, 255);
    border: 1px solid #e8eaec;
    font-size: 18px;
}

.table-div {
    border: 1px solid #e8eaec;
    height: 50px;
    box-sizing: border-box;
}

.table-div-title {
    font-weight: 700;
}

.company_name {
    width: 100%;
    height: 30px;
    /*border: 1px solid #5A8CFF;*/
}

.table-font {
    font-size: 35px;
}

.vxe-table-element {
    cursor: pointer;
}
</style>
