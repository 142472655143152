<template>
    <el-row :gutter="0">
        <el-col :span="24">
            <vxe-toolbar>
                <template v-slot:buttons>
                    <div class="flex justify-between">
                        <div>
                            <vxe-button
                                @click="change"
                                style="margin-right: 50px"
                                >返回</vxe-button
                            >
                            <el-date-picker
                                v-model="condition.date"
                                type="daterange"
                                class="input searchInput"
                                value-format="yyyy-MM-dd"
                                size="mini"
                                :unlink-panels="true"
                                placeholder="选择日期"
                                @change="changeCondition"
                            />
                        </div>
                        <div>
                            <vxe-button @click="getList">刷新</vxe-button>
                            <vxe-button @click="exportTableE"
                                >导出excel</vxe-button
                            >
                            <!--              <vxe-button @click="exportTableP">导出PDF</vxe-button>-->
                        </div>
                    </div>
                </template>
            </vxe-toolbar>
            <div>
                <div class="flex">
                    <div class="flex-2">
                        <div class="flex">
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                学员姓名
                            </div>
                            <div
                                ref="train"
                                class="flex-1 table-div flex justify-center align-center"
                            >
                                {{ user.realName }}
                            </div>
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                企业名称
                            </div>
                            <div
                                ref="trainTime"
                                class="flex-1 table-div flex justify-center align-center"
                            >
                                {{ user.companyName }}
                            </div>
                        </div>
                        <div class="flex">
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                身份证号
                            </div>
                            <div
                                ref="company_pass_num"
                                class="flex-1 table-div flex justify-center align-center"
                            >
                                {{ user.idCard }}
                            </div>
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                手机号
                            </div>
                            <div
                                ref="company_rate"
                                class="flex-1 table-div flex justify-center align-center"
                            >
                                {{ user.phone }}
                            </div>
                        </div>
                        <div class="flex">
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                车牌号
                            </div>
                            <div
                                ref="company_pass_num"
                                class="flex-1 table-div"
                            >
                                {{ user.carNum }}
                            </div>
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                岗位
                            </div>
                            <div
                                ref="company_rate"
                                class="flex-1 table-div flex justify-center align-center"
                            >
                                {{ user.positionName }}
                            </div>
                        </div>
                        <div class="flex">
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                学习开始日期
                            </div>
                            <div
                                ref="company_pass_num"
                                class="flex-1 table-div flex justify-center align-center"
                            >
                                {{ user.startTime }}
                            </div>
                            <div
                                class="flex-1 table-div table-div-title flex justify-center align-center"
                            >
                                学习结束日期
                            </div>
                            <div
                                ref="company_rate"
                                class="flex-1 table-div flex justify-center align-center"
                            >
                                {{ user.endTime }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="table-div-title"
                        style="
                            width: 250px;
                            height: 195px;
                            border: 1px solid #e8eaec;
                            padding: 2px;
                        "
                    >
                        <img
                            v-if="user.headUrl"
                            :src="$fileUrl + user.headUrl"
                            alt=""
                            style="width: 244px; height: 195px; display: block"
                        />
                        <img
                            v-if="!user.headUrl"
                            style="width: 100%; height: 100%"
                            src="../../../../assets/user.png"
                        />
                    </div>
                </div>
                <div class="flex">
                    <div
                        class="flex-1 table-div-title flex justify-center align-center"
                        style="height: auto; border: 1px solid #e8eaec"
                    >
                        学习中照片
                    </div>
                    <div
                        ref="trainTitle"
                        class="flex-2 flex justify-center align-center"
                        style="height: 100px"
                    >
                        <img
                            v-for="(v, i) in user.filePaths"
                            :key="i"
                            :src="$fileUrl + v"
                            alt=""
                            class="w-inherit"
                            style="
                                height: 100px;
                                width: 100px;
                                display: block;
                                margin-left: 20px;
                            "
                        />
                    </div>
                </div>
                <vxe-table
                    ref="table"
                    border
                    export-config
                    resizable
                    :auto-resize="true"
                    :row-style="$rowStyle"
                    align="center"
                    class="vxe-table-element"
                    :data="table.list"
                    :loading="loading"
                    height="330"
                >
                    <vxe-table-column title="序号" type="seq" width="60" />
                    <vxe-table-column field="endTime" title="学习时间" />
                    <vxe-table-column field="courseName" title="课程名称" />
                    <vxe-table-column field="videoTitle" title="视频名称" />
                    <vxe-table-column
                        field="watchProgressStr"
                        title="学习时长"
                    />
                    <vxe-table-column field="videoSchedule" title="学习进度(%)">
                        <template v-slot="{ row }">
                            <span>{{ row.videoSchedule }}</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="isCompleteStr" title="完成情况" />
                    <vxe-table-column title="学习签名">
                        <template v-slot="{ row }">
                            <img
                                :src="fileUrl + row.signUrl"
                                style="width: 80px"
                                v-if="row.signUrl"
                            />
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </el-col>
    </el-row>
</template>

<script>
import { staTraineeDetails, staTraineeDetailsE } from "@/api";
import { bytesToFile } from "@/utils/handleByte";
import { fileUrl } from "@/api/Global";
export default {
    name: "Index",
    props: {
        condition: "",
    },
    data() {
        return {
            fileUrl,
            loading: false,
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            user: {
                realName: "",
                companyName: "",
                idCard: "",
                carNum: "",
                headUrl: "",
                departName: "",
                positionName: "",
                startTime: "",
                endTime: "",
                filePaths: [],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.table.list = [];
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.table.list = [];
            this.getList();
        },
        changeCondition() {
            this.getList();
        },
        change() {
            this.condition.date = null;
            this.$emit("change-table", { isShow: true });
        },
        exportTableE() {
            this.loading = true;
            this.$axiosReq(
                staTraineeDetailsE,
                null,
                {
                    id: this.condition.row.id,
                    startTime: this.condition.date
                        ? this.condition.date[0]
                        : "",
                    endTime: this.condition.date ? this.condition.date[1] : "",
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                staTraineeDetails,
                null,
                {
                    id: this.condition.row.id,
                    startTime: this.condition.date
                        ? this.condition.date[0]
                        : "",
                    endTime: this.condition.date ? this.condition.date[1] : "",
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.pageVo.content;
                    this.table.total = Number(res.data.pageVo.totalElements);
                    this.user = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-head {
    width: 100%;
    color: rgb(92, 139, 255);
    border: 1px solid #e8eaec;
    font-size: 18px;
}

.table-div {
    border: 1px solid #e8eaec;
    height: 50px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
.table-div::-webkit-scrollbar {
    display: none;
}

.table-div-title {
    font-weight: 700;
}

.company_name {
    width: 100%;
    height: 30px;
    /*border: 1px solid #5A8CFF;*/
}

.table-font {
    font-size: 35px;
}
</style>
