var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',[[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[(_vm.searchUrl === _vm.details)?_c('vxe-button',{on:{"click":_vm.returnTable}},[_vm._v("返回")]):_vm._e(),_c('div'),_c('div',[_c('vxe-button',{on:{"click":function($event){return _vm.getList()}}},[_vm._v("刷新")]),(_vm.$hasBtn('sys:permissions:report:safety:import'))?_c('vxe-button',{on:{"click":_vm.exportTableE}},[_vm._v("导出excel")]):_vm._e()],1)],1)]},proxy:true}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension == 0),expression:"condition.statisticalDimension == 0"}]},[(_vm.level != 3)?_c('div',[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"630","data":_vm.table.list,"loading":_vm.loading},on:{"cell-click":_vm.cellClick}},[_c('vxe-table-column',{attrs:{"field":"areaName","title":"区域"}}),_c('vxe-table-column',{attrs:{"field":"areaNum","title":"所辖区域"}}),_c('vxe-table-column',{attrs:{"field":"completeAreaNum","title":"完成区域"}}),_c('vxe-table-column',{attrs:{"field":"undoneAreaNum","title":"未完成区域"}}),_c('vxe-table-column',{attrs:{"field":"completionRate","title":"完成率(%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.completionRate))])]}}],null,false,4208607426)})],1)],1):_c('div',[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"550","data":_vm.table.list,"loading":_vm.loading},on:{"cell-click":_vm.cellClick}},[_c('vxe-table-column',{attrs:{"field":"areaName","title":"区域"}}),_c('vxe-table-column',{attrs:{"field":"isCompleteStr","title":"完成情况"}})],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension == 1),expression:"condition.statisticalDimension == 1"}]},[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"550","data":_vm.table.list,"loading":_vm.loading},on:{"cell-click":_vm.cellClick}},[_c('vxe-table-column',{attrs:{"field":"areaName","title":"区域"}}),_c('vxe-table-column',{attrs:{"field":"companyNum","title":"企业总数","cell-type":"string"}}),_c('vxe-table-column',{attrs:{"field":"completeCompanyNum","title":"完成企业数"}}),_c('vxe-table-column',{attrs:{"field":"undoneCompanyNum","title":"未完成企业数"}}),_c('vxe-table-column',{attrs:{"field":"completionRate","title":"完成率(%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.completionRate))])]}}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension == 2),expression:"condition.statisticalDimension == 2"}]},[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"550","data":_vm.table.list,"loading":_vm.loading},on:{"cell-click":_vm.cellClick}},[_c('vxe-table-column',{attrs:{"field":"areaName","title":"区域"}}),_c('vxe-table-column',{attrs:{"field":"courseNum","title":"课程总数","cell-type":"string"}}),_c('vxe-table-column',{attrs:{"field":"completeCourseNum","title":"完成课程数"}}),_c('vxe-table-column',{attrs:{"field":"undoneCourseNum","title":"未完成课程数"}}),_c('vxe-table-column',{attrs:{"field":"processingCourseNum","title":"进行中课程数"}}),_c('vxe-table-column',{attrs:{"field":"completionRate","title":"完成率(%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.completionRate))])]}}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension == 3),expression:"condition.statisticalDimension == 3"}]},[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"550","data":_vm.table.list,"loading":_vm.loading},on:{"cell-click":_vm.cellClick}},[_c('vxe-table-column',{attrs:{"field":"areaName","title":"区域"}}),_c('vxe-table-column',{attrs:{"field":"traineeNum","title":"学员总数","cell-type":"string"}}),_c('vxe-table-column',{attrs:{"field":"completeTraineeNum","title":"完成学员数"}}),_c('vxe-table-column',{attrs:{"field":"undoneTraineeNum","title":"未完成学员数"}}),_c('vxe-table-column',{attrs:{"field":"completionRate","title":"完成率(%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.completionRate))])]}}])})],1)],1)],_c('div',{staticStyle:{"height":"20px"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }