<template>
    <el-row>
        <template>
            <vxe-toolbar>
                <template v-slot:buttons>
                    <div class="flex justify-between">
                        <vxe-button
                            v-if="searchUrl === details"
                            @click="returnTable"
                            >返回</vxe-button
                        >
                        <div />
                        <div>
                            <vxe-button @click="getList()">刷新</vxe-button>
                            <vxe-button v-if="$hasBtn('sys:permissions:report:safety:import')" @click="exportTableE"
                                >导出excel</vxe-button
                            >
                            <!--              <vxe-button @click="exportTableP">导出PDF</vxe-button>-->
                        </div>
                    </div>
                </template>
            </vxe-toolbar>
            <div v-show="condition.statisticalDimension == 0">
                <!-- <div v-if="table.list[0].level != 3"> -->
                <div v-if="level != 3">
                    <vxe-table
                        ref="table"
                        border
                        resizable
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyle"
                        align="center"
                        class="vxe-table-element"
                        height="630"
                        :data="table.list"
                        :loading="loading"
                        @cell-click="cellClick"
                    >
                        <vxe-table-column field="areaName" title="区域" />
                        <vxe-table-column field="areaNum" title="所辖区域" />
                        <vxe-table-column
                            field="completeAreaNum"
                            title="完成区域"
                        />
                        <vxe-table-column
                            field="undoneAreaNum"
                            title="未完成区域"
                        />
                        <vxe-table-column
                            field="completionRate"
                            title="完成率(%)"
                        >
                            <template v-slot="{ row }">
                                <span>{{ row.completionRate }}</span>
                            </template>
                        </vxe-table-column>
                    </vxe-table>
                </div>
                <div v-else>
                    <vxe-table
                        ref="table"
                        border
                        resizable
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyle"
                        align="center"
                        class="vxe-table-element"
                        height="550"
                        :data="table.list"
                        :loading="loading"
                        @cell-click="cellClick"
                    >
                        <vxe-table-column field="areaName" title="区域" />
                        <vxe-table-column
                            field="isCompleteStr"
                            title="完成情况"
                        />
                    </vxe-table>
                </div>
            </div>
            <div v-show="condition.statisticalDimension == 1">
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="550"
                    :data="table.list"
                    :loading="loading"
                    @cell-click="cellClick"
                >
                    <vxe-table-column field="areaName" title="区域" />
                    <vxe-table-column
                        field="companyNum"
                        title="企业总数"
                        cell-type="string"
                    />
                    <vxe-table-column
                        field="completeCompanyNum"
                        title="完成企业数"
                    />
                    <vxe-table-column
                        field="undoneCompanyNum"
                        title="未完成企业数"
                    />
                    <vxe-table-column field="completionRate" title="完成率(%)">
                        <template v-slot="{ row }">
                            <span>{{ row.completionRate }}</span>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
            <div v-show="condition.statisticalDimension == 2">
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="550"
                    :data="table.list"
                    :loading="loading"
                    @cell-click="cellClick"
                >
                    <vxe-table-column field="areaName" title="区域" />
                    <vxe-table-column
                        field="courseNum"
                        title="课程总数"
                        cell-type="string"
                    />
                    <vxe-table-column
                        field="completeCourseNum"
                        title="完成课程数"
                    />
                    <vxe-table-column
                        field="undoneCourseNum"
                        title="未完成课程数"
                    />
                    <vxe-table-column
                        field="processingCourseNum"
                        title="进行中课程数"
                    />
                    <vxe-table-column field="completionRate" title="完成率(%)">
                        <template v-slot="{ row }">
                            <span>{{ row.completionRate }}</span>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
            <div v-show="condition.statisticalDimension == 3">
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="550"
                    :data="table.list"
                    :loading="loading"
                    @cell-click="cellClick"
                >
                    <vxe-table-column field="areaName" title="区域" />
                    <vxe-table-column
                        field="traineeNum"
                        title="学员总数"
                        cell-type="string"
                    />
                    <vxe-table-column
                        field="completeTraineeNum"
                        title="完成学员数"
                    />
                    <vxe-table-column
                        field="undoneTraineeNum"
                        title="未完成学员数"
                    />
                    <vxe-table-column field="completionRate" title="完成率(%)">
                        <template v-slot="{ row }">
                            <span>{{ row.completionRate }}</span>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
        </template>
        <div style="height: 20px" />
        <!-- <el-pagination
      background
      :current-page="table.currentPage"
      :page-sizes="[5, 10, 15, 20, 50, 100]"
      :page-size="table.pageSize"
      layout="total, ->, sizes, prev, pager, next, jumper"
      :total="table.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    /> -->
    </el-row>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { details, inquire, detailsE, inquireE } from "@/api";
import { bytesToFile } from "@/utils/handleByte";

export default {
    name: "Index",
    components: {},
    props: {
        condition: {
            type: [Number, String, Array, Object],
            default: "",
        },
    },
    data() {
        return {
            loading: false,
            columnShow: false,
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 1000,
                sortName: "",
                sortBy: "",
            },
            searchUrl: inquire,
            details,
            inquire,
            exportUrl: inquireE,
            level: 1,
        };
    },
    watch: {
        condition: {
            handler(v) {
                this.table.currentPage = 1;
                this.searchUrl = inquire;
                this.exportUrl = inquireE;
                this.getList();
            },
            deep: true,
        },
    },
    beforeCreate() {},
    created() {
        // this.getList()
    },
    mounted() {
        this.getList();
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        checkColumnMethod({ column }) {
            return true;
        },
        cellClick(v) {
            this.condition.region = v.areaCode;
            if (this.table.list[0].level != 3) {
                this.searchUrl = details;
                this.exportUrl = detailsE;
                this.getList();
            }
        },
        returnTable() {
            this.searchUrl = inquire;
            this.exportUrl = inquireE;
            this.getList();
        },
        exportTableE() {
            this.loading = true;
            this.$axiosReq(
                this.exportUrl,
                null,
                {
                    statisticalDimension: this.condition.statisticalDimension,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    areaCode: this.condition.areaCode,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getList() {
            //console.log(this.condition);

            this.loading = true;
            this.$axiosReq(
                this.searchUrl,
                null,
                {
                    statisticalDimension: this.condition.statisticalDimension,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    areaCode: this.condition.areaCode,
                    currentPage:
                        this.searchUrl === inquire
                            ? null
                            : this.table.currentPage,
                    pageSize:
                        this.searchUrl === inquire ? null : this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    if (this.searchUrl === inquire) {
                        this.table.list = [res.data];
                    } else {
                        this.table.list = res.data.content;
                    }
                    this.level = this.table.list[0].level;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
    },
};
</script>

<style scoped>
	.vxe-table-element{
		cursor: pointer;
	}
</style>
