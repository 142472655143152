<template>
    <div>
        <el-row>
            <template>
                <!-- <vxe-toolbar>
					<template v-slot:buttons>
						<div class="flex justify-between">
							<vxe-button @click="change">返回</vxe-button>
							<div>
								<vxe-button @click="batchExport">批量导出试卷</vxe-button>
								<vxe-button @click="getList">刷新</vxe-button>
								<vxe-button v-if="$hasBtn('sys:permissions:report:safety:import')" @click="exportTableE">导出excel</vxe-button>
							</div>
						</div>
					</template>
				</vxe-toolbar> -->
                <div class="flex justify-between" style="padding-bottom: 5px">
                    <div
                        style="height: 52px; display: flex; align-items: center"
                    >
                        <vxe-button @click="change">返回</vxe-button>
                        <span
                            class="text-primary text-pad-right"
                            style="margin-left: 10px"
                            >人员搜索：</span
                        >
                        <el-input
                            v-model="userName"
                            size="mini"
                            class="input searchInput"
                            suffix-icon="el-icon-search"
                            placeholder="请输入学员姓名"
                            style="width: 250px"
                            @input="onSearch"
                        />

                        <div class="pl-1 pr-1">
                            <span class="text-primary text-pad-right"
                                >部门:
                            </span>
                            <!-- <el-select v-model="condition.departId" clearable filterable size="mini" class="input searchInput" @change="changeJob">
						<el-option v-for="(item,index) in depart" :key="index" :value="item.id" :label="item.departName" />
					</el-select> -->
                            <el-cascader
                                ref="dept"
                                v-model="departId"
                                :options="depart"
                                class="input searchInput"
                                size="mini"
                                :props="{
                                    expandTrigger: 'hover',
                                    value: 'id',
                                    children: 'sons',
                                    label: 'departName',
                                    emitPath: false,
                                    checkStrictly: true,
                                }"
                                filterable
                                clearable
                                @change="onSearch"
                            />
                        </div>

                        <div class="pl-1 pr-1">
                            <span class="text-primary text-pad-right"
                                >考试完成签名:
                            </span>

                            <GlobalDictSelect
                                v-model="isSign"
                                size="mini"
                                placeholder="请选择考试完成签名状态"
                                parentCode="TESTED_SIGN_STATUS"
                                style="width: 250px"
                                @change="onSearch"
                            />
                        </div>
                    </div>
                    <div>
                        <vxe-toolbar ref="xToolbar" custom>
                            <template v-slot:buttons>
                                <!-- <el-row type="flex" justify="between">

                            </el-row> -->
                                <div
                                    style="
                                        height: 52px;
                                        display: flex;
                                        align-items: center;
                                    "
                                >
                                    <vxe-button @click="batchExport"
                                        >批量导出试卷</vxe-button
                                    >
                                    <vxe-button @click="getList"
                                        >刷新</vxe-button
                                    >
                                    <vxe-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:report:safety:import'
                                            ) && isCompanyType
                                        "
                                        @click="onClickExport"
                                        >导出excel</vxe-button
                                    >
                                    <vxe-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:report:safety:import'
                                            ) && !isCompanyType
                                        "
                                        @click="onClickExport2"
                                        >导出excel</vxe-button
                                    >
                                </div>
                            </template>
                        </vxe-toolbar>
                    </div>
                </div>
                <div class="table-head">
                    <div
                        ref="company_name"
                        class="company_name table-div-title flex justify-center align-center"
                    >
                        {{ companyHead.courseName }}
                    </div>
                    <!-- <div class="flex">

						<div class="flex-1 table-div table-div-title flex justify-center align-center">课程有效期</div>
						<div ref="trainTime" class="flex-1 table-div flex justify-center align-center" style="color: #333333;">{{ companyHead.startTime+'-'+companyHead.endTime }}
						</div>
						<div class="flex-1 table-div table-div-title flex justify-center align-center">培训类型</div>
						<div ref="train" class="flex-1 table-div flex justify-center align-center" style="color: #333333;">{{ companyHead.trainTypeStr }}</div>

					</div> -->
                    <div class="flex">
                        <div
                            class="flex-1 table-div table-div-title flex justify-center align-center"
                        >
                            课程有效期
                        </div>
                        <div
                            ref="amount_user"
                            class="flex-1 table-div flex justify-center align-center"
                            style="color: #333333"
                        >
                            {{
                                companyHead.startTime
                                    ? companyHead.startTime + "-"
                                    : ""
                            }}{{
                                companyHead.endTime ? companyHead.endTime : ""
                            }}
                        </div>
                        <div
                            class="flex-1 table-div table-div-title flex justify-center align-center"
                        >
                            培训类型
                        </div>
                        <div
                            ref="company_pass_num"
                            class="flex-1 table-div flex justify-center align-center"
                            style="color: #333333"
                        >
                            {{ companyHead.trainTypeStr }}
                        </div>
                        <div
                            class="flex-1 table-div table-div-title flex justify-center align-center"
                        >
                            课程时长
                        </div>
                        <div
                            ref="company_rate"
                            class="flex-1 table-div flex justify-center align-center"
                            style="color: #333333"
                        >
                            {{ companyHead.courseLength }}
                        </div>
                    </div>
                    <div class="flex">
                        <div
                            class="flex-1 table-div table-div-title flex justify-center align-center"
                        >
                            学员总数
                        </div>
                        <div
                            ref="amount_user"
                            class="flex-1 table-div flex justify-center align-center"
                            style="color: #333333"
                        >
                            {{ companyHead.traineeNum }}
                        </div>
                        <div
                            class="flex-1 table-div table-div-title flex justify-center align-center"
                        >
                            完成学员数
                        </div>
                        <div
                            ref="company_pass_num"
                            class="flex-1 table-div flex justify-center align-center"
                            style="color: #333333"
                        >
                            {{ companyHead.completeTraineeNum }}
                        </div>
                        <div
                            class="flex-1 table-div table-div-title flex justify-center align-center"
                        >
                            学员完成率(%)
                        </div>
                        <div
                            ref="company_rate"
                            class="flex-1 table-div flex justify-center align-center"
                            style="color: #333333"
                        >
                            {{ companyHead.completionRate }}
                        </div>
                    </div>
                    <div class="flex">
                        <div
                            class="flex-1 table-div table-div-title flex justify-center align-center"
                            style="height: auto; padding-right: 9px"
                        >
                            培训内容
                        </div>
                        <div
                            ref="trainContent"
                            class="flex-2 table-div flex justify-center introduceClass"
                        >
                            {{ companyHead.courseIntroduce }}
                        </div>
                    </div>
                </div>
                <vxe-table
                    :custom-config="{ storage: true }"
                    id="user_id"
                    @custom="handleCustom"
                    ref="vxeTable"
                    border
                    export-config
                    resizable
                    :column-key="true"
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    :data="table.list"
                    :print-config="{}"
                    :loading="loading"
                    height="445"
                >
                    <vxe-table-column
                        field="num"
                        title="序号"
                        type="seq"
                        width="60"
                    />
                    <vxe-table-column
                        field="realName"
                        title="学员姓名"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="carNum"
                        title="车牌号"
                        show-header-overflow
                        show-overflow="title"
                        show-footer-overflow
                    />
                    <vxe-table-column
                        field="positionName"
                        title="岗位"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="departName"
                        title="部门"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="phone"
                        title="手机号码"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        :header-render="{}"
                        title="学习进度"
                        field="courseSchedule"
                    >
                        <template #header>
                            <div>
                                学习进度
                                <i
                                    @click="directionClicl"
                                    v-if="table.direction == 'ASC'"
                                    class="el-icon-caret-top"
                                ></i>
                                <i
                                    @click="directionClicl"
                                    v-if="table.direction == 'DESC'"
                                    class="el-icon-caret-bottom"
                                ></i>
                            </div>
                        </template>
                        <template v-slot="{ row }">
                            <span>{{ row.courseSchedule }}%</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        field="isCompleteStr"
                        title="完成情况"
                        show-overflow="title"
                    />
                    <vxe-table-column field="testNum" title="考试次数" />
                    <vxe-table-column title="考试成绩" field="testScore">
                        <template v-slot="{ row }">
                            <span
                                @click="openBox(row.id)"
                                style="
                                    color: #007aff;
                                    border-bottom: 1px solid #007aff;
                                    cursor: pointer;
                                "
                                >{{ row.testScore }}</span
                            >
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="学习签名" field="signUrl">
                        <template v-slot="{ row }">
                            <img
                                :src="fileUrl + row.signUrl"
                                style="width: 80px"
                                v-if="row.signUrl"
                            />
                        </template>
                    </vxe-table-column>
                    <!--  -->
                    <vxe-table-column field="headUrl" title="学员照片">
                        <template v-slot="{ row }">
                            <el-image
                                style="width: 80px"
                                :src="fileUrl + row.headUrl"
                                fit="contain"
                            ></el-image>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </template>
            <el-pagination
                background
                :current-page="table.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size="table.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="table.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                style="position: relative; top: 10px"
            />
        </el-row>
        <div v-if="boxShowHide" class="newBox">
            <div class="boxShow">
                <el-row :gutter="20">
                    <el-col :span="1">
                        <div style="width: 1px; height: 1px"></div>
                    </el-col>
                    <el-col :span="22">
                        <div
                            class="text-center"
                            style="font-size: 18px; font-weight: bold"
                        >
                            学习情况
                        </div>
                    </el-col>
                    <el-col :span="1">
                        <i
                            @click="closeBox"
                            class="el-icon-error"
                            style="font-size: 22px; cursor: pointer"
                        ></i>
                    </el-col>
                </el-row>
                <div style="width: 90%; margin: 0 auto; font-size: 16px">
                    <el-row
                        :gutter="22"
                        style="padding-top: 10px; font-weight: bold"
                    >
                        <el-col :span="21">
                            <div
                                style="
                                    height: 25px;
                                    line-height: 25px;
                                    border-left: 3px solid #0c8b43;
                                    padding-left: 6px;
                                "
                            >
                                基本情况
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <vxe-button @click="testExport">导出</vxe-button>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="22"
                        style="padding-top: 10px; font-weight: bold"
                    >
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                姓 名:
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    detailList.userName
                                        ? detailList.userName
                                        : "--"
                                }}
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                部 门:
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    detailList.departName
                                        ? detailList.departName
                                        : "--"
                                }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="22"
                        style="padding-top: 10px; font-weight: bold"
                    >
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                学习时长:
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    detailList.studyLength
                                        ? detailList.studyLength
                                        : "--"
                                }}
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                课程完成率:
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    detailList.complateRate
                                        ? detailList.complateRate
                                        : "--"
                                }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="22"
                        style="padding-top: 10px; font-weight: bold"
                    >
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                考试成绩:
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    detailList.testScore
                                        ? detailList.testScore
                                        : "--"
                                }}
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                完成时间:
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    detailList.complateTime
                                        ? detailList.complateTime
                                        : "--"
                                }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="22"
                        style="padding-top: 10px; font-weight: bold"
                    >
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                学习内容:
                            </div>
                        </el-col>
                        <el-col :span="21">
                            <div class="introduceClass-study">
                                {{
                                    detailList.courseIntroduce
                                        ? detailList.courseIntroduce
                                        : "--"
                                }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="22"
                        style="padding-top: 10px; font-weight: bold"
                    >
                        <el-col>
                            <div
                                style="
                                    height: 25px;
                                    line-height: 25px;
                                    border-left: 3px solid #0c8b43;
                                    padding-left: 6px;
                                "
                            >
                                答题情况
                            </div>
                        </el-col>
                    </el-row>
                    <div
                        v-if="listTopic1.length > 0"
                        style="font-size: 16px; padding-top: 15px"
                    >
                        ● 单选题
                    </div>
                    <div v-for="(item, index) in listTopic1" :key="index">
                        <div style="padding-top: 15px; line-height: 25px">
                            <span
                                style="color: #00be00"
                                v-if="
                                    JSON.stringify(item.correctOptions) ===
                                    JSON.stringify(item.userOptions)
                                "
                                >（√）</span
                            >
                            <span
                                style="color: #ff5500"
                                v-if="
                                    JSON.stringify(item.correctOptions) !==
                                    JSON.stringify(item.userOptions)
                                "
                                >（×）</span
                            >
                            {{ item.topicTitle }}
                        </div>
                        <div class="optionClass">
                            <div
                                style="padding: 0 10px; line-height: 25px"
                                v-for="(items, indexs) in item.baseTopicOptions"
                                :key="indexs"
                            >
                                {{ items.options }}, {{ items.remarks }}
                                <!-- <span class="xuanxiang" :class="{borderActive : items.isChoose === 0}">{{items.options}}</span>
							{{items.remarks}} -->
                            </div>
                        </div>
                        <div style="padding-top: 20px">
                            正确答案：<span
                                v-for="(items, indexs) in item.correctOptions"
                                :key="indexs"
                                >{{ items }}</span
                            >
                            您的答案：<span
                                v-for="(items, indexs) in item.userOptions"
                                :key="indexs"
                                >{{ items }}</span
                            >
                        </div>
                    </div>
                    <div
                        v-if="listTopic3.length > 0"
                        style="font-size: 16px; padding-top: 15px"
                    >
                        ● 判断题
                    </div>
                    <div v-for="(item, index) in listTopic3" :key="index">
                        <div style="padding-top: 15px; line-height: 25px">
                            <span
                                style="color: #00be00"
                                v-if="
                                    JSON.stringify(item.correctOptions) ===
                                    JSON.stringify(item.userOptions)
                                "
                                >（√）</span
                            >
                            <span
                                style="color: #ff5500"
                                v-if="
                                    JSON.stringify(item.correctOptions) !==
                                    JSON.stringify(item.userOptions)
                                "
                                >（×）</span
                            >
                            {{ item.topicTitle }}
                        </div>
                        <div class="optionClass">
                            <div
                                style="padding: 0 10px; line-height: 25px"
                                v-for="(items, indexs) in item.baseTopicOptions"
                                :key="indexs"
                            >
                                {{ items.options }}, {{ items.remarks }}
                            </div>
                        </div>
                        <div style="padding-top: 20px">
                            正确答案：<span
                                v-for="(items, indexs) in item.correctOptions"
                                :key="indexs"
                                >{{ items }}</span
                            >
                            您的答案：<span
                                v-for="(items, indexs) in item.userOptions"
                                :key="indexs"
                                >{{ items }}</span
                            >
                        </div>
                    </div>
                    <div
                        v-if="listTopic2.length > 0"
                        style="font-size: 16px; padding-top: 15px"
                    >
                        ● 多选题
                    </div>
                    <div v-for="(item, index) in listTopic2" :key="index">
                        <div style="padding-top: 15px; line-height: 25px">
                            <span
                                style="color: #00be00"
                                v-if="
                                    JSON.stringify(item.correctOptions) ===
                                    JSON.stringify(item.userOptions)
                                "
                                >（√）</span
                            >
                            <span
                                style="color: #ff5500"
                                v-if="
                                    JSON.stringify(item.correctOptions) !==
                                    JSON.stringify(item.userOptions)
                                "
                                >（×）</span
                            >
                            {{ item.topicTitle }}
                        </div>
                        <div class="optionClass">
                            <div
                                style="padding: 0 10px; line-height: 25px"
                                v-for="(items, indexs) in item.baseTopicOptions"
                                :key="indexs"
                            >
                                {{ items.options }}, {{ items.remarks }}
                            </div>
                        </div>
                        <div style="padding-top: 20px">
                            正确答案：<span
                                v-for="(items, indexs) in item.correctOptions"
                                :key="indexs"
                                >{{ items }}</span
                            >
                            您的答案：<span
                                v-for="(items, indexs) in item.userOptions"
                                :key="indexs"
                                >{{ items }}</span
                            >
                        </div>
                    </div>

                    <div
                        style="
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            margin-top: 30px;
                            padding-right: 100px;
                        "
                    >
                        <span>签名:</span>
                        <img
                            :src="fileUrl + detailList.signUrl"
                            style="width: 80px"
                            v-if="detailList.signUrl"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import {
    staCompanyCourse,
    staCompanyCourseE,
    staFirmCourse,
    staFirmCourseE,
} from "@/api";
import { bytesToFile } from "@/utils/handleByte";
import { fileUrl } from "@/api/Global";
import { depart } from "@/api";
export default {
    beforeCreate() {
        // 显示/隐藏列功能，通过表格设置 id 和 custom-config={storage: true} 参数开启将列个性化 localStorage 保存功能
        // 通过 custom 事件实现显示/隐藏列服务端保存，
        window.localStorage.setItem(
            "VXE_TABLE_CUSTOM_COLUMN_VISIBLE",
            JSON.stringify({ _v: 0, user_id: "headUrl" })
        );
    },
    destroyed() {
        window.localStorage.setItem(
            "VXE_TABLE_CUSTOM_COLUMN_VISIBLE",
            JSON.stringify("")
        );
    },
    name: "Index",
    components: {},
    props: {
        condition: "",
    },
    data() {
        return {
            fileUrl,
            isSign: "",
            userName: null,
            departId: "",
            tableHeight: 600,
            loading: false,
            columnShow: false,
            exportDisplay: "show",
            table: {
                list: [],
                head: [],
                exportList: [[]],
                total: 0,
                pageTotal: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
                allList: [],
                direction: "DESC",
            },
            companyHead: {},
            boxShowHide: false,
            detailList: {},
            listTopic: [],
            userId: "",
            listTopic1: [],
            listTopic2: [],
            listTopic3: [],
            depart: [],

            columns: [
                // "num",
                // "realName",
                // "carNum",
                // "positionName",
                // "departName",
                // "phone",
                // "isCompleteStr",
                // "testNum",
                // "testScore",
                // "signUrl",
                // "sign",

                "num",
                "realName",
                "carNum",
                "positionName",
                "departName",
                "phone",
                "courseSchedule",
                "isCompleteStr",
                "testNum",
                "testScore",
                "signUrl",
            ],
        };
    },
    watch: {
        condition: {
            handler(v) {
                // this.table.list = []
                // this.table.exportList = []
                // this.table.currentPage = 1
                // this.getList()
            },
            deep: true,
        },
    },
    created() {
        this.getList();
        this.$nextTick(() => {
            // 手动将表格和工具栏进行关联
            this.$refs.vxeTable.connect(this.$refs.xToolbar);
        });

        this.getDepart();
    },
    computed: {
        isCompanyType() {
            if (sessionStorage.getItem("CompanyType") == "0") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        rowStyle,
        headerStyle,

        onSearch() {
            this.table.currentPage = 1;
            this.getList();
        },
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        change() {
            this.condition.row.id = this.condition.row.companyId;
            this.$emit("change-table", {
                number: 2,
                row: this.condition.row,
            });
        },
        getDepart() {
            this.$axiosReq(
                depart,
                null,
                {
                    companyId: this.condition.row.companyId,
                    type: 1,
                },
                "get"
            ).then((res) => {
                this.depart = res.data;
            });
        },
        directionClicl() {
            if (this.table.direction == "DESC") {
                this.table.direction = "ASC";
            } else {
                this.table.direction = "DESC";
            }
            this.getList();
        },

        handleCustom(e) {
            console.log(e.type);
            const columns = JSON.parse(
                JSON.stringify(e.$table.$toolbar.columns)
            );

            this.columns = columns
                .filter((item) => item.visible)
                .map((item) => item.property)
                .filter((item) => item);
            console.log(this.columns);
            // this.columns = e.$table.$toolbar.columns.filter((item) => {
            //     if (item.property) {
            //         return item;
            //     }
            // });
            // this.columns.forEach((item, index) => {
            //     console.log(index, item.title, item.property, item.visible);
            // });
            // property title  visible  checked
        },

        // 点击导出excel按钮
        async onClickExport() {
            this.loading = true;
            let fields = JSON.parse(JSON.stringify(this.columns));
            // .filter((item) => item.visible)
            // .map((item) => item.property)
            // .filter((item) => item);
            console.log(fields);
            const isImportImg = fields.find((item) => item == "headUrl")
                ? 0
                : 1; // 是否导出图片，0要1不要

            const sign = fields.find((item) => item == "signUrl") ? "sign" : "";
            sign && fields.push(sign);
            fields = Array.from(new Set(fields));

            // console.log(sign);

            let { code, data } = await this.$api.get(
                "/course/server/escort/web/statistics/company/course/export",
                {
                    params: {
                        companyId: this.condition.row.companyId,
                        courseId: this.condition.row.id,
                        isImportImg: isImportImg, // 是否导出图片，0要1不要
                        fields: fields + "",
                        isSign: this.isSign,
                        departId: this.departId,
                        userName: this.userName,
                    },
                }
            );
            if (code == 200) {
                bytesToFile(data.bytes, data.fileName);
                this.loading = false;
            }
            this.loading = false;

            // this.$confirm(
            //     "因导出数据较多，请确认导出的excel是否包含学员照片，如果是，请点击”照片导出“按钮；如果否，请点击”普通导出“按钮。",
            //     "导出excel格式",
            //     {
            //         distinguishCancelAndClose: true,
            //         confirmButtonText: "普通导出",
            //         cancelButtonText: "照片导出",
            //     }
            // )
            //     .then(async () => {
            //         this.loading = true;
            //         let res = await this.$axiosReq(
            //             "/course/server/escort/web/statistics/company/course/export",
            //             null,
            //             {
            //                 companyId: this.condition.row.companyId,
            //                 courseId: this.condition.row.id,
            //                 isImportImg: "1", // 是否导出图片，0要1不要
            //             },
            //             "get"
            //         );
            //         bytesToFile(res.data.bytes, res.data.fileName);
            //         this.loading = false;
            //         // .then((res) => {
            //         //     bytesToFile(res.data.bytes, res.data.fileName);
            //         // })
            //         // .finally((e) => {
            //         // });
            //     })
            //     .catch((action) => {
            //         if (action != "close") {
            //             this.loading = true;
            //             this.$axiosReq(
            //                 "/course/server/escort/web/statistics/company/course/export",
            //                 null,
            //                 {
            //                     companyId: this.condition.row.companyId,
            //                     courseId: this.condition.row.id,
            //                     isImportImg: "0",
            //                 },
            //                 "get"
            //             )
            //                 .then((res) => {
            //                     bytesToFile(res.data.bytes, res.data.fileName);
            //                 })
            //                 .finally((e) => {
            //                     this.loading = false;
            //                 });
            //         }
            //     });
        },
        async onClickExport2() {
            this.loading = true;
            let fields = JSON.parse(JSON.stringify(this.columns));
            // .filter((item) => item.visible)
            // .map((item) => item.property)
            // .filter((item) => item);
            console.log(fields);
            const isImportImg = fields.find((item) => item == "headUrl")
                ? 0
                : 1; // 是否导出图片，0要1不要

            const sign = fields.find((item) => item == "signUrl") ? "sign" : "";
            sign && fields.push(sign);
            fields = Array.from(new Set(fields));

            let { code, data } = await this.$api.get(
                `/course/server/escort/web/statistics/firm/export/courseId/${this.condition.row.id}`,
                {
                    params: {
                        companyId: this.condition.row.companyId,
                        courseId: this.condition.row.id,
                        isImportImg: isImportImg, // 是否导出图片，0要1不要
                        fields: fields + "",
                        direction: "DESC",
                        isSign: this.isSign,
                        departId: this.departId,
                        userName: this.userName,
                    },
                }
            );
            if (code == 200) {
                bytesToFile(data.bytes, data.fileName);
                this.loading = false;
            }
            this.loading = false;
            // this.$confirm(
            //     "因导出数据较多，请确认导出的excel是否包含学员照片，如果是，请点击”照片导出“按钮；如果否，请点击”普通导出“按钮。",
            //     "导出excel格式",
            //     {
            //         distinguishCancelAndClose: true,
            //         confirmButtonText: "普通导出",
            //         cancelButtonText: "照片导出",
            //     }
            // )
            //     .then(() => {
            //         this.loading = true;
            //         this.$axiosReq(
            //             `/course/server/escort/web/statistics/firm/export/courseId/${this.condition.row.id}`,
            //             null,
            //             {
            //                 companyId: this.condition.row.companyId,
            //                 courseId: this.condition.row.id,
            //                 isImportImg: "1",
            //                 direction: "DESC",
            //             },
            //             "get"
            //         )
            //             .then((res) => {
            //                 bytesToFile(res.data.bytes, res.data.fileName);
            //             })
            //             .finally((e) => {
            //                 this.loading = false;
            //             });
            //     })
            //     .catch((action) => {
            //         if (action != "close") {
            //             this.loading = true;
            //             this.$axiosReq(
            //                 `/course/server/escort/web/statistics/firm/export/courseId/${this.condition.row.id}`,
            //                 null,
            //                 {
            //                     companyId: this.condition.row.companyId,
            //                     courseId: this.condition.row.id,
            //                     isImportImg: "0",
            //                     direction: "DESC",
            //                 },
            //                 "get"
            //             )
            //                 .then((res) => {
            //                     bytesToFile(res.data.bytes, res.data.fileName);
            //                 })
            //                 .finally(() => {
            //                     this.loading = false;
            //                 });
            //         }
            //     });
        },

        // 导出excel
        exportTableE() {
            this.loading = true;
            if (this.$companyType == 0) {
                this.$axiosReq(
                    staCompanyCourseE,
                    null,
                    {
                        companyName: this.condition.name,
                        companyId: this.condition.row.companyId,
                        courseId: this.condition.row.id,
                        currentPage: this.table.currentPage,
                        pageSize: this.table.pageSize,
                        direction: this.table.direction,
                    },
                    "get"
                )
                    .then((res) => {
                        bytesToFile(res.data.bytes, res.data.fileName);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            } else {
                this.$axiosReq(
                    staFirmCourseE + this.condition.row.id,
                    null,
                    null,
                    "get"
                )
                    .then((res) => {
                        bytesToFile(res.data.bytes, res.data.fileName);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            }
        },
        testExport() {
            this.loading = true;
            this.$axiosReq(
                "/course/server/escort/web/statistics/score/test/export",
                null,
                {
                    courseId: this.condition.row.id,
                    userId: this.userId,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        batchExport() {
            this.loading = true;
            this.$axiosReq(
                "/course/server/escort/web/statistics/score/batch/export",
                null,
                {
                    courseId: this.condition.row.id,
                    companyId: this.condition.row.companyId,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getList() {
            this.loading = true;
            if (this.$companyType == 0) {
                this.$axiosReq(
                    staCompanyCourse,
                    null,
                    {
                        companyName: this.condition.name,
                        userName: this.userName,
                        departId: this.departId,
                        isSign: this.isSign,
                        companyId: this.condition.row.companyId,
                        courseId: this.condition.row.id,
                        currentPage: this.table.currentPage,
                        pageSize: this.table.pageSize,
                        direction: this.table.direction,
                    },
                    "get"
                )
                    .then((res) => {
                        this.table.list = res.data.pageVo.content;
                        this.table.total = Number(
                            res.data.pageVo.totalElements
                        );
                        this.companyHead = res.data;
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            } else {
                this.$axiosReq(
                    staFirmCourse,
                    null,
                    {
                        userName: this.userName,
                        departId: this.departId,
                        isSign: this.isSign,
                        courseId: this.condition.row.id,
                        currentPage: this.table.currentPage,
                        pageSize: this.table.pageSize,
                        direction: this.table.direction,
                    },
                    "get"
                )
                    .then((res) => {
                        this.table.list = res.data.pageVo.content;
                        this.table.total = Number(
                            res.data.pageVo.totalElements
                        );
                        this.companyHead = res.data;
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            }
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        closeBox() {
            this.boxShowHide = false;
        },
        openBox(id) {
            this.boxShowHide = true;
            this.loading = true;
            this.userId = id;
            this.listTopic = [];
            this.listTopic1 = [];
            this.listTopic2 = [];
            this.listTopic3 = [];
            this.$axiosReq(
                // "/course/server/escort/web/statistics/safe/score/detail",
                "/course/server/escort/web/statistics/score/detail",
                null,
                {
                    courseId: this.condition.row.id,
                    userId: id,
                },
                "get"
            )
                .then((res) => {
                    this.detailList = res.data;
                    if (res.data.topicInfos && res.data.topicInfos.length > 0) {
                        this.listTopic = res.data.topicInfos;
                        this.listTopic.forEach((item) => {
                            if (item.topicType === 0) {
                                this.listTopic1.push(item);
                            } else if (item.topicType === 1) {
                                this.listTopic2.push(item);
                            } else if (item.topicType === 2) {
                                this.listTopic3.push(item);
                            }
                        });
                    } else {
                        this.listTopic = [];
                    }
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.table-head {
    width: 100%;
    color: rgb(92, 139, 255);
    border: 1px solid #e8eaec;
    font-size: 18px;
}

.table-div {
    border: 1px solid #e8eaec;
    height: 40px;
    box-sizing: border-box;
}

.table-div-title {
    font-weight: 700;
}

.company_name {
    width: 100%;
    height: 30px;
    /*border: 1px solid #5A8CFF;*/
}

.table-font {
    font-size: 35px;
}

.introduceClass {
    height: 90px;
    padding: 10px;
    color: #333333;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.introduceClass-study {
    height: 30px;
    line-height: 30px;
    color: #007aff;
    height: 90px;
    /*padding: 10px;*/
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.introduceClass::-webkit-scrollbar {
    display: none;
}

.el-icon-caret-top,
.el-icon-caret-bottom {
    color: #007aff;
    font-size: 20px;
    display: inline-block;
    cursor: pointer;
}

.newBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.boxShow {
    width: 55%;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    background-color: #ffffff;
    margin: 90px auto 0;
    border-radius: 5px;
    padding: 15px;
}

.boxShow::-webkit-scrollbar {
    display: none;
}

.optionClass {
    width: 100%;
    padding-top: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
}
</style>
