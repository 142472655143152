<template>
    <div class="flex">
        <div>
            <span class="text-primary text-pad-right">日期</span>
            <el-date-picker
                v-model="condition.date"
                type="daterange"
                class="input searchInput"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                size="mini"
                align="center"
                :unlink-panels="true"
                placeholder="选择日期"
                @change="changeDate"
            />
        </div>
        <div class="ml-2">
            <span class="text-primary text-pad-right">行政区域</span>
            <el-cascader
                ref="region"
                v-model="condition.areaCode"
                :options="region"
                filterable
                class="input searchInput"
                size="mini"
                :props="{
                    lazy: $admin,
                    lazyLoad: lazyRegion,
                    value: 'areaCode',
                    children: 'children',
                    label: 'areaName',
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    emitPath: false,
                }"
                @change="changeRegion"
            />
        </div>
        <div class="ml-2">
            <span class="text-primary text-pad-right">统计维度</span>
            <el-select
                v-model="condition.statisticalDimension"
                class="input searchInput"
                size="mini"
                @change="changeType"
            >
                <el-option
                    v-for="(v, i) in staType"
                    :key="i"
                    :value="v.dictCode"
                    :label="v.dictName"
                />
            </el-select>
        </div>
    </div>
</template>

<script>
import { getDictParentCode, getAreaChildren, getAreaTreeByCode } from "@/api";

export default {
    name: "TotalSearch",
    data() {
        return {
            loading: false,
            condition: {
                statisticalDimension: "0",
                areaCode: "",
                date: "",
                area: {},
            },
            region: [],
            staType: [],
            count: 0,
        };
    },

    created() {
        this.getStatisticType();
        if (!this.$admin) this.getAreaChildren();
    },
    mounted() {
        this.changeType(this.condition.statisticalDimension);
    },
    methods: {
        getStatisticType() {
            this.$axiosReq(
                getDictParentCode + "STATISTICAL_DIMENSION",
                null,
                null,
                "get"
            ).then((res) => {
                this.staType = res.data;
            });
        },
        lazyRegion(v, resolve) {
            // 异步加载子节点
            this.$axiosReq(
                getAreaChildren,
                null,
                {
                    parentId: v.data?.id,
                },
                "get"
            ).then((res) => {
                const arr = [];
                for (const i of res.data) {
                    arr.push({
                        ...i,
                        hasChild: i.level < 3,
                    });
                }
                if (this.count === 0) {
                    this.condition.areaCode = sessionStorage.RegionCode;
                }
                this.count++;
                resolve(arr);
            });
        },
        getAreaChildren() {
            this.loading = true;
            this.$axiosReq(
                getAreaTreeByCode + this.$regionCode,
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.region = [res.data];
                    this.condition.areaCode = this.region[0].areaCode;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        changeRegion(v) {
            const node = this.$refs.region.getCheckedNodes();
            this.$emit("get-level", node[0].data);
            this.$emit("get-region", v);
        },
        changeDate(v) {
            if (v) {
                this.$emit("get-start", v[0]);
                this.$emit("get-end", v[1]);
            } else {
                this.$emit("get-start", "");
                this.$emit("get-end", "");
            }
        },
        changeType(v) {
            this.$emit("get-type", v);
        },
    },
};
</script>

<style scoped></style>
