<template>
    <div>
        <el-row>
            <vxe-toolbar>
                <template v-slot:buttons>
                    <div class="flex justify-end">
                        <vxe-button @click="getList">刷新</vxe-button>
                        <vxe-button @click="onDownload"
                            >批量导出学习记录</vxe-button
                        >

                        <vxe-button
                            v-if="
                                $hasBtn('sys:permissions:report:safety:import')
                            "
                            @click="exportTableE"
                            >导出excel</vxe-button
                        >
                        <!--          <vxe-button @click="exportTableP">导出PDF</vxe-button>-->
                    </div>
                </template>
            </vxe-toolbar>
            <vxe-table
                ref="table"
                border
                resizable
                :auto-resize="true"
                :header-row-style="headerStyle"
                :row-style="rowStyle"
                align="center"
                class="vxe-table-element"
                height="600"
                :data="table.list"
                :loading="loading"
                @cell-click="cellClick"
            >
                <vxe-table-column type="seq" width="60" />
                <vxe-table-column field="realName" title="姓名" />
                <vxe-table-column field="companyName" title="企业" />
                <vxe-table-column field="headUrl" title="头像">
                    <template v-slot="{ row }">
                        <el-popover
                            placement="right"
                            trigger="hover"
                            v-if="row.headUrl"
                        >
                            <img
                                :src="$fileUrl + row.headUrl"
                                alt=""
                                style="width: 500px; height: 500px"
                            />
                            <img
                                slot="reference"
                                :src="$fileUrl + row.headUrl"
                                alt=""
                                style="width: 100px; height: 100px"
                            />
                        </el-popover>
                        <img
                            v-else
                            :src="require('@/assets/user.png')"
                            alt=""
                            style="width: 100px; height: 100px"
                        />
                    </template>
                </vxe-table-column>
                <vxe-table-column field="idCard" title="身份证号码" />
                <vxe-table-column field="phone" title="手机号码" />
                <vxe-table-column field="departName" title="部门" />
                <vxe-table-column field="isJobStr" title="是否离职">
                </vxe-table-column>
            </vxe-table>
            <div style="height: 20px" />
            <el-pagination
                background
                :current-page="table.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size="table.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="table.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-row>
        <el-dialog
            title="批量导出学习记录"
            :visible.sync="dialogVisible"
            width="30%"
            :append-to-body="true"
            :destroy-on-close="true"
        >
            <el-form ref="refElForm" label-width="100px">
                <el-form-item :required="true" :label="`${'选择时间'}:`">
                    <el-date-picker
                        v-model="timeArr"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import {
    inquire,
    staFirmTrainee,
    staFirmTraineeE,
    staTrainee,
    staTraineeE,
    company,
} from "@/api";
import { bytesToFile } from "@/utils/handleByte";
export default {
    name: "Index",
    components: {},
    props: {
        condition: "",
    },
    data() {
        return {
            dialogVisible: false,
            timeArr: [], // 导出时间范围
            loading: false,
            loading2: false,
            loading3: false,
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                show: false,
                refresh: false,
                info: {},
                row: {},
            },
            searchDate: "",
        };
    },
    watch: {
        condition: {
            handler(v) {
                this.table.currentPage = 1;
                this.getList();
            },
            deep: true,
        },
    },
    created() {
        this.getCompany();
    },
    methods: {
        rowStyle,
        headerStyle,
        openView(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        cellClick({ row }) {
            this.$emit("change-table", { isShow: false, row: row });
        },
        async onConfirm() {
            if (this.timeArr == null) {
                this.$message({
                    message: "请选择时间范围!",
                    type: "warning",
                });
                return;
            }
            if (this.timeArr.length < 2) {
                this.$message({
                    message: "请选择时间范围!",
                    type: "warning",
                });
                return;
            }
            let { code, data } = await this.$api.get(
                "/course/server/escort/web/statistics/trainee/details/batch/export",

                {
                    params: {
                        companyId: this.condition.companyId,
                        phone: this.condition.phone,
                        realName: this.condition.realName,
                        startTime: this.timeArr[0],
                        endTime: this.timeArr[1],
                        currentPage: this.table.currentPage,
                        pageSize: this.table.pageSize,
                    },
                }
            );
            if (code == 200) {
                bytesToFile(data.bytes, data.fileName);
                this.dialogVisible = false;
            }
        },
        onDownload() {
            this.timeArr = [];
            this.dialogVisible = true;
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                if (!this.condition.companyId) {
                    this.condition.companyId = res.data.content[0].id;
                }
                this.getList();
            });
        },
        exportTableE() {
            this.loading = true;
            if (this.$companyType == 0) {
                this.$axiosReq(
                    staTraineeE,
                    null,
                    {
                        companyId: this.condition.companyId,
                        phone: this.condition.phone,
                        realName: this.condition.realName,
                    },
                    "get"
                )
                    .then((res) => {
                        bytesToFile(res.data.bytes, res.data.fileName);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            } else {
                this.$axiosReq(
                    staFirmTraineeE,
                    null,
                    {
                        phone: this.condition.phone,
                        realName: this.condition.realName,
                    },
                    "get"
                )
                    .then((res) => {
                        bytesToFile(res.data.bytes, res.data.fileName);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            }
        },
        getList() {
            this.loading = true;
            if (this.$companyType == 0) {
                this.$axiosReq(
                    staTrainee,
                    null,
                    {
                        companyId: this.condition.companyId,
                        phone: this.condition.phone,
                        realName: this.condition.realName,
                        currentPage:
                            this.searchUrl === inquire
                                ? null
                                : this.table.currentPage,
                        pageSize:
                            this.searchUrl === inquire
                                ? null
                                : this.table.pageSize,
                    },
                    "get"
                )
                    .then((res) => {
                        if (this.searchUrl === inquire) {
                            this.table.list = [res.data];
                        } else {
                            this.table.list = res.data.content;
                        }
                        this.table.total = Number(res.data.totalElements);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            } else {
                this.$axiosReq(
                    staFirmTrainee,
                    null,
                    {
                        phone: this.condition.phone,
                        realName: this.condition.realName,
                        currentPage:
                            this.searchUrl === inquire
                                ? null
                                : this.table.currentPage,
                        pageSize:
                            this.searchUrl === inquire
                                ? null
                                : this.table.pageSize,
                    },
                    "get"
                )
                    .then((res) => {
                        if (this.searchUrl === inquire) {
                            this.table.list = [res.data];
                        } else {
                            this.table.list = res.data.content;
                        }
                        this.table.total = Number(res.data.totalElements);
                    })
                    .finally((e) => {
                        this.loading = false;
                    });
            }
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        handleSizeChange2(pageSize) {
            this.table2.pageSize = pageSize;
            this.table2.currentPage = 1;
            this.setStudentTow(this.table2.row);
        },
        handleCurrentChange2(currentPage) {
            this.table2.currentPage = currentPage;
            this.setStudentTow(this.table2.row);
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.vxe-table-element {
    cursor: pointer;
}
.el-date-editor {
    ::v-deep .el-range-input {
        background-color: #f0f0f0;
    }
}
</style>
