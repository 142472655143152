var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticStyle:{"position":"relative","height":"705px"}},[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('vxe-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.changeTable),expression:"changeTable"}],on:{"click":_vm.returnTable}},[_vm._v("返回")]),_c('div'),_c('div',[_c('vxe-button',{on:{"click":_vm.getList}},[_vm._v("刷新")]),(_vm.$hasBtn('sys:permissions:report:safety:import'))?_c('vxe-button',{on:{"click":_vm.exportTableE}},[_vm._v("导出excel")]):_vm._e()],1)],1)]},proxy:true}])}),_c('div',{attrs:{"id":"table1"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension==0),expression:"condition.statisticalDimension==0"}]},[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"600","data":_vm.table.list,"loading":_vm.loading},on:{"cell-click":_vm.cellClick}},[_c('vxe-table-column',{attrs:{"field":"courseName","title":"课程名称","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"title":"有效期","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.startTime+'-'+row.endTime))])]}}])}),_c('vxe-table-column',{attrs:{"field":"areaNum","title":"区域总数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"completeAreaNum","title":"完成区域数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"undoneAreaNum","title":"未完成区域数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"areaCompletionRate","title":"完成率(%)","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.areaCompletionRate))])]}}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension==1),expression:"condition.statisticalDimension==1"}]},[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"550","data":_vm.table.list,"loading":_vm.loading},on:{"cell-click":_vm.cellClick}},[_c('vxe-table-column',{attrs:{"field":"courseName","title":"课程名称","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"title":"有效期","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.startTime+'-'+row.endTime))])]}}])}),_c('vxe-table-column',{attrs:{"field":"companyNum","title":"企业总数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"completeCompanyNum","title":"完成企业数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"undoneCompanyNum","title":"未完成企业数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"companyCompletionRate","title":"企业完成率(%)","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"traineeNum","title":"学员总数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"completeTraineeNum","title":"完成学员数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"undoneTraineeNum","title":"未完成学员数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"traineeCompletionRate","title":"学员完成率(%)","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.traineeCompletionRate))])]}}])})],1)],1)]),_c('div',{attrs:{"id":"table2"}},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"cell-outer cell-outer-title"},[_vm._v("课程名称")]),_c('div',{staticClass:"cell-outer cell-outer-title flex-1"},[_vm._v(" "+_vm._s(_vm.course.courseName)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"cell-outer cell-outer-title"},[_vm._v("课程详情")]),_c('div',{staticClass:"cell-outer cell-outer-title flex-1"},[_vm._v(" "+_vm._s(_vm.course.courseIntroduce)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"cell-outer cell-outer-title"},[_vm._v("课程有效期")]),_c('div',{staticClass:"cell-outer cell-outer-title flex-1"},[_vm._v(" "+_vm._s(_vm.course.startTime+'-'+_vm.course.endTime)+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension==0),expression:"condition.statisticalDimension==0"}]},[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":_vm.changeHeight,"data":_vm.table.list,"loading":_vm.loading}},[_c('vxe-table-column',{attrs:{"field":"areaName","title":"行政区域","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"companyNum","title":"企业总数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"completeCompanyNum","title":"完成企业数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"undoneCompanyNum","title":"未完成企业数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"completionRate","title":"完成率(%)","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.completionRate))])]}}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.condition.statisticalDimension==1),expression:"condition.statisticalDimension==1"}]},[_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":_vm.changeHeight,"data":_vm.table.list,"loading":_vm.loading}},[_c('vxe-table-column',{attrs:{"field":"companyName","title":"企业名称","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"areaName","title":"所属区域","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"traineeNum","title":"学员总数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"completeTraineeNum","title":"完成学员数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"undoneTraineeNum","title":"未完成学员数","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"completionRate","title":"完成率(%)","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.completionRate))])]}}])})],1)],1)]),_c('div',{staticStyle:{"height":"20px","margin-top":"600px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.table.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.table.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }