<template>
    <div class="dashboard-container">
        <el-row type="flex" justify="space-between" :gutter="50">
            <el-col>
                <el-tabs
                    v-model="tabs"
                    type="border-card"
                    @tab-click="changeTab"
                >
                    <template v-if="$companyType == 0">
                        <el-tab-pane
                            name="0"
                            lazy
                            label="统计查询"
                            v-if="
                                $hasBtn(
                                    'sys:permissions:report:safety:training:statistics:query'
                                )
                            "
                        >
                            <TotalSearch
                                @get-region="getRegionT"
                                @get-start="getStartT"
                                @get-end="getEndT"
                                @get-type="getTypeT"
                                @get-level="getLevelT"
                            />
                        </el-tab-pane>
                        <el-tab-pane
                            name="1"
                            label="课程查询"
                            v-if="
                                $hasBtn('sys:permissions:safety:course:query')
                            "
                        >
                            <CourseSearch
                                @get-region="getRegionCo"
                                @get-start="getStartCo"
                                @get-end="getEndCo"
                                @get-type="getTypeCo"
                                @get-name="getNameCo"
                                @get-gran="getGranCo"
                            ></CourseSearch>
                        </el-tab-pane>
                    </template>
                    <el-tab-pane
                        name="2"
                        lazy
                        :label="$companyType == 0 ? '企业查询' : '统计查询'"
                        v-if="$hasBtn('sys:permissions:safety:business:query')"
                    >
                        <CompanySearch
                            ref="CompanySearch"
                            @get-company="getCompanyC"
                            @get-start="getDateC"
                            @get-end="getEndC"
                            :table-number="tableNumber"
                        >
                        </CompanySearch>
                    </el-tab-pane>
                    <el-tab-pane
                        name="3"
                        lazy
                        label="学员查询"
                        v-if="$hasBtn('sys:permissions:safety:student:query')"
                    >
                        <UserSearch
                            @get-name="getNameU"
                            @get-company="getCompanyU"
                            @get-phone="getPhoneU"
                        />
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <el-card style="margin-top: 20px">
            <div v-if="tabs == 0">
                <Province :condition="totalCondition" />
            </div>
            <div v-else-if="tabs == 1">
                <Course :condition="courseCondition"></Course>
            </div>
            <div v-else-if="tabs == 2">
                <Company
                    :condition="comCondition"
                    @enter-course="enterCourse"
                    ref="Company"
                    @table-show="getTableNum"
                ></Company>
            </div>
            <div v-else-if="tabs == 3">
                <UserMain :condition="userCondition" />
            </div>
        </el-card>
    </div>
</template>

<script>
import Province from "./Total/Province";
import TotalSearch from "./Total/TotalSearch";
import Company from "./Company";
import CompanySearch from "./Company/CompanySearch";
import UserMain from "./User";
import UserSearch from "./User/UserSearch";
import Course from "./Course";
import CourseSearch from "./Course/CourseSearch";

export default {
    name: "Index",
    components: {
        TotalSearch,
        Course,
        CourseSearch,
        CompanySearch,
        UserMain,
        UserSearch,
        Province,
        Company,
    },
    data() {
        return {
            tabs: this.$companyType == 0 ? "0" : "2",
            totalCondition: {
                areaCode: "",
                startTime: "",
                endTime: "",
                statisticalDimension: "",
                area: "",
            },
            courseCondition: {
                areaCode: "",
                startTime: "",
                endTime: "",
                courseName: "",
                statisticalDimension: "",
                statisticalGranularity: "",
            },
            comCondition: {
                name: "",
                startTime: "",
                endTime: "",
            },
            userCondition: {
                companyId: "",
                realName: "",
                phone: "",
            },
            tableNumber: 1,
        };
    },
    created() {},
    methods: {
        enterCourse() {
            this.$refs.CompanySearch.condition.name = "";
            // this.$refs.CompanySearch.condition.date = '';
        },

        getRegionT(v) {
            this.totalCondition.areaCode = v;
        },
        getStartT(v) {
            this.totalCondition.startTime = v;
        },
        getEndT(v) {
            this.totalCondition.endTime = v;
        },
        getTypeT(v) {
            this.totalCondition.statisticalDimension = v;
        },
        getLevelT(v) {
            this.totalCondition.area = v;
        },
        getRegionCo(v) {
            this.courseCondition.areaCode = v;
        },
        getStartCo(v) {
            this.courseCondition.startTime = v;
        },
        getEndCo(v) {
            this.courseCondition.endTime = v;
        },
        getTypeCo(v) {
            this.courseCondition.statisticalDimension = v;
        },
        getNameCo(v) {
            this.courseCondition.courseName = v;
        },
        getGranCo(v) {
            this.courseCondition.statisticalGranularity = v;
        },
        getCompanyC(v) {
            if (v) {
                this.comCondition.name = v;
            } else {
                this.comCondition.name = "";
            }
        },
        getDateC(v) {
            this.comCondition.startTime = v;
        },
        getEndC(v) {
            this.comCondition.endTime = v;
        },
        getCompanyU(v) {
            this.userCondition.companyId = v;
        },
        getNameU(v) {
            this.userCondition.realName = v;
        },
        getPhoneU(v) {
            this.userCondition.phone = v;
        },
        getTableNum(v) {
            this.tableNumber = v;
        },
        changeTab(v) {
            this.tableNumber = 1;
        },
    },
};
</script>

<style scoped></style>
