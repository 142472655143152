<template>
  <div class="flex">
    <div v-if="$companyType==0&&tableNumber<3">
      <span class="text-primary text-pad-right">{{tableNumber==1?'公司':'课程'}}</span>
      <el-input :placeholder="`输入${tableNumber==1?'公司':'课程'}`"
        v-model="condition.name"
                :clearable="tableNumber==1?false:true"
        size="mini"
        class="input searchInput"
        style="width: 200px"
        @input="changeCompany"
      />
    </div>
    <div v-if="$companyType==1">
      <span class="text-primary text-pad-right">课程</span>
      <el-input
        v-model="condition.name"
        size="mini"
        class="input searchInput"
        style="width: 200px"
        @input="changeCompany"
      />
    </div>
    <div v-show="($companyType==0&&tableNumber<3)||$companyType==1" class="ml-2">
      <span class="text-primary text-pad-right">日期</span>
      <el-date-picker
        v-model="condition.date"
        type="daterange"
        class="input searchInput"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        size="mini"
        :unlink-panels="true"
        placeholder="选择日期"
        @change="changeDate"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanySearch',
  props:{
    tableNumber:{
      type: Number,
      default:1
    }
  },
  data() {
    return {
      loading: false,
      condition: {
        date: '',
        name: ''
      },
      company: []
    }
  },
  watch:{
    tableNumber(v){
        console.log(v);

      if (v != 2) {
        // this.condition.name=''
        // this.condition.date=''
        this.changeCompany(this.condition.name)
        this.changeDate(this.condition.date)
      } else {
        // this.condition.name = '';
        this.changeCompany(this.condition.name)
      }
      if(v==1){
          this.condition.date='';
          this.condition.name = '';
          this.changeCompany(this.condition.name)
          this.changeDate(this.condition.date)
      }
    }
  },
  created() {

  },
  methods: {
    changeCompany(v) {
      this.$emit('get-company', v)
    },
    changeDate(v) {
      if (v) {
        this.$emit('get-start', v[0])
        this.$emit('get-end', v[1])
      } else {
        this.$emit('get-start', '')
        this.$emit('get-end', '')
      }
    }
  }
}
</script>

<style scoped>

</style>
